import { Link, Title } from "@solidjs/meta";
import ContentLayout from "~/layouts/ContentLayout";
import { secondarypaths } from "~/configs/paths";

const meta = {
  title: `${secondarypaths.licenses.title} | Jonathan Lau`,
  canonical: `${import.meta.env.VITE_APP_BASE_URL}${secondarypaths.licenses.path}`,
};
const License = () => {
  return (
    <>
      <>
        <Title>{meta.title}</Title>
        <Link rel="canonical" href={`${meta.canonical}`} />
      </>
      <ContentLayout as="content">
        <section class="flow">
          <h2 class="text-2xl md:whitespace-nowrap">
            Copyright © 2024 Jonathan Lau
          </h2>
          <p>
            Permission is hereby granted, free of charge, to any person
            obtaining a copy of this software and associated documentation files
            (the "Software"), to deal in the Software without restriction,
            including without limitation the rights to use, copy, modify, merge,
            publish, distribute, sublicense, and/or sell copies of the Software,
            and to permit persons to whom the Software is furnished to do so,
            subject to the following conditions: The above copyright notice and
            this permission notice shall be included in all copies or
            substantial portions of the Software. THE SOFTWARE IS PROVIDED "AS
            IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING BUT
            NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY, FITNESS FOR A
            PARTICULAR PURPOSE AND NONINFRINGEMENT. IN NO EVENT SHALL THE
            AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM, DAMAGES OR
            OTHER LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR
            OTHERWISE, ARISING FROM, OUT OF OR IN CONNECTION WITH THE SOFTWARE
            OR THE USE OR OTHER DEALINGS IN THE SOFTWARE.
          </p>
        </section>

        <section class="jl-stack fl-gap fl-m-l">
          <h2 class="text-2xl md:whitespace-nowrap">
            Font Awesome Pro License
          </h2>
          <p>
            --- Font Awesome Pro is commercial software that requires a paid
            license. Full Font Awesome Pro license:
            https://fontawesome.com/license.
          </p>
          <h3 class="text-xl">Commercial License</h3>
          <p>
            The Font Awesome Pro commercial license allows you to pay for FA Pro
            once, own it, and use it just about everywhere you'd like.
          </p>
          <h3 class="text-xl md:whitespace-nowrap">Attribution</h3>
          <p>
            Attribution is not required by the Font Awesome Pro commercial
            license.
          </p>
          <h3 class="text-xl">Brand Icons</h3>
          <p>
            All brand icons are trademarks of their respective owners. The use
            of these trademarks does not indicate endorsement of the trademark
            holder by Font Awesome, nor vice versa. **Please do not use brand
            logos for any purpose except to represent the company, product, or
            service to which they refer.** ---
          </p>
          <h2 class="text-2xl md:whitespace-nowrap">
            Streamline Handdrawn Icons
          </h2>
          Hand drawn icons provided by Streamline
          <h3 class="text-xl">CC BY 4.0 DEED</h3>
          Canonical URL https://creativecommons.org/licenses/by/4.0/
          <h3 class="text-xl">Free Usage With Attribution</h3>
          <span>
            <a
              href="https://streamlinehq.com"
              class="text-primary-50 text-xl mb-3"
            >
              Free Icons from Streamline
            </a>
          </span>
        </section>
      </ContentLayout>
    </>
  );
};
export default License;
